import React, { useContext, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";

import {
  ModalContext,
  TempDataContext,
  FormDataContext,
  BusinessDataContext,
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

import mtn from "../../images/mtn.png";
import glo from "../../images/glo.png";
import airtel from "../../images/airtel.png";
import etisalat from "../../images/etisalat.png";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);

  document.title = "Dashboard | DataNow";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  useEffect(() => {
    if (!tempData.fetchStats) {
      fetchStats();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "fetchStats", value: true },
      });
    }
  }, []);

  var interval;

  const setRefreshInterval = (refreshTime) => {
    clearInterval(interval); // thanks @Luca D'Amico
    if (refreshTime) {
      interval = setInterval(function () {
        fetchStats();
      }, refreshTime);
    }
  };

  const fetchStats = (key, value) => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving Stats...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("key", String(key));
    urlencoded.append("value", String(value));

    var requestOptions = {
      method: key ? "post" : "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (key) {
      requestOptions = { ...requestOptions, body: urlencoded };
    }
    fetch(localStorage.getItem("apiURL") + "dashboard", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "data", value: data.data },
          });
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Row className="justify-content-center align-content-center m-1">
        <Col xl="12" ml="12" sm="12" className=" p-0 m-0">
          <Card className="mini-stat text-dark shadow pb-4">
            <CardBody>
              {tempData.data && (
                <React.Fragment>
                  <div>
                    <select
                      className=" border-primary border rounded p-2"
                      value={formData.refreshInterval}
                      name="refreshInterval"
                      onChange={(e) => {
                        setRefreshInterval(e.target.value);
                        formOnChange(e);
                      }}
                    >
                      <option value="">Manual Refresh</option>
                      <option value="30000">Refresh Every 30 Seconds</option>
                      <option value="60000">Refresh Every Minute</option>
                      <option value="300000">Refresh Every 5 Minutes</option>
                    </select>
                  </div>
                  <Row>
                    <Col lg="6" xs="12">
                      <div className="mb-2">
                        <p className="text-uppercase text-left text-primary  p-0 m-0 ">
                          <b>Subscriptions Today</b>
                        </p>
                        <hr className=" m-1 p-0" />
                      </div>
                      <Row>
                        <Col lg="3" xs="6">
                          <Card className="mini-stat  shadow">
                            <CardBody
                              className={
                                tempData.data.queuedTransactionsCount * 1 > 100
                                  ? "bg-danger text-white p-2"
                                  : "bg-success text-white p-2"
                              }
                            >
                              <p className="m-0 p-0">
                                <b>Queued</b>
                              </p>
                              <p className="m-0 p-0">
                                <b>
                                  {" "}
                                  {formatCurrency(
                                    tempData.data.queuedTransactionsCount
                                  )}
                                </b>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col lg="3" xs="6">
                          <Card className="mini-stat  shadow">
                            <CardBody
                              className={
                                tempData.data.chargingTransactionsCount * 1 > 10
                                  ? "bg-danger text-white p-2"
                                  : "bg-success text-white p-2"
                              }
                            >
                              <p className="m-0 p-0">Charging</p>
                              <p className="m-0 p-0">
                                <b>
                                  {" "}
                                  {formatCurrency(
                                    tempData.data.chargingTransactionsCount
                                  )}
                                </b>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col lg="3" xs="6">
                          <Card className="mini-stat  shadow">
                            <CardBody
                              className={
                                tempData.data.chargingUserTransactionsCount *
                                  1 >
                                20
                                  ? "bg-danger text-white p-2"
                                  : "bg-success text-white p-2"
                              }
                            >
                              <p className="m-0 p-0">Paying</p>
                              <p className="m-0 p-0">
                                <b>
                                  {" "}
                                  {formatCurrency(
                                    tempData.data.chargingUserTransactionsCount
                                  )}
                                </b>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col lg="3" xs="6">
                          <Card className="mini-stat  shadow">
                            <CardBody
                              className={
                                tempData.data.reversedTransactionsCount * 1 >
                                1000
                                  ? "bg-danger text-white p-2"
                                  : "bg-success text-white p-2"
                              }
                            >
                              <p className="m-0 p-0">Reversed</p>
                              <p className="m-0 p-0">
                                <b>
                                  {" "}
                                  {formatCurrency(
                                    tempData.data.reversedTransactionsCount
                                  )}
                                </b>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col lg="3" xs="6">
                          <Card className="mini-stat  shadow">
                            <CardBody
                              className={
                                tempData.data.processingTransactionsCount * 1 >
                                50
                                  ? "bg-danger text-white p-2"
                                  : "bg-success text-white p-2"
                              }
                            >
                              <p className="m-0 p-0">Processing</p>
                              <p className="m-0 p-0">
                                <b>
                                  {" "}
                                  {formatCurrency(
                                    tempData.data.processingTransactionsCount
                                  )}
                                </b>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col lg="3" xs="6">
                          <Card className="mini-stat  shadow">
                            <CardBody className="bg-success text-white p-2">
                              <p className="m-0 p-0">Delivered</p>
                              <p className="m-0 p-0">
                                <b>
                                  {" "}
                                  {formatCurrency(
                                    tempData.data.deliveredTransactionsCount
                                  )}
                                </b>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>

                    <Col lg="6" xs="12">
                      <div className="mb-2">
                        <p className="text-uppercase text-left text-primary  p-0 m-0 ">
                          <b>WALLETS</b>
                        </p>
                        <hr className=" m-1 p-0" />
                      </div>
                      <Row>
                        <Col lg="3" xs="6">
                          <Card className="mini-stat  shadow">
                            <CardBody
                              className={
                                tempData.data.VTPASS * 1 < 200000
                                  ? "bg-danger text-white p-2"
                                  : "bg-success text-white p-2"
                              }
                            >
                              <p className="m-0 p-0">
                                <b>VTPASS</b>
                              </p>
                              <p className="m-0 p-0">
                                <b> ₦{formatCurrency(tempData.data.VTPASS)}</b>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col lg="3" xs="6">
                          <Card className="mini-stat  shadow">
                            <CardBody
                              className={
                                tempData.data.GOLAD * 1 < 200000
                                  ? "bg-danger text-white p-2"
                                  : "bg-success text-white p-2"
                              }
                            >
                              <p className="m-0 p-0 small">GOLAD Airtime</p>
                              <p className="m-0 p-0">
                                <b> ₦{formatCurrency(tempData.data.GOLAD)}</b>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col lg="3" xs="6">
                          <Card className="mini-stat  shadow">
                            <CardBody
                              className={
                                tempData.data.GOLADDATA * 1 < 2000000
                                  ? "bg-danger text-white p-2"
                                  : "bg-success text-white p-2"
                              }
                            >
                              <p className="m-0 p-0 small">GOLAD Data</p>
                              <p className="m-0 p-0">
                                <b>
                                  {" "}
                                  ₦{formatCurrency(tempData.data.GOLADDATA)}
                                </b>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col lg="3" xs="6">
                          <Card className="mini-stat  shadow">
                            <CardBody
                              className={
                                tempData.data.SAGECLOUD * 1 < 200000
                                  ? "bg-danger text-white p-2"
                                  : "bg-success text-white p-2"
                              }
                            >
                              <p className="m-0 p-0">SAGECLOUD</p>
                              <p className="m-0 p-0">
                                <b>
                                  {" "}
                                  ₦{formatCurrency(tempData.data.SAGECLOUD)}
                                </b>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col lg="3" xs="6">
                          <Card className="mini-stat  shadow">
                            <CardBody
                              className={
                                tempData.data.MTNCGBalance * 1 < 200000
                                  ? "bg-danger text-white p-2"
                                  : "bg-success text-white p-2"
                              }
                            >
                              <p className="m-0 p-0">MTN CG Balance</p>
                              <p className="m-0 p-0">
                                <b>
                                  {formatCurrency(
                                    tempData.data.MTNCGBalance / 1024
                                  )}
                                  GB
                                </b>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col lg="3" xs="6">
                          <Card className="mini-stat  shadow">
                            <CardBody
                              className={
                                tempData.data.marketplace_mtn_data * 1 < 500000
                                  ? "bg-danger text-white p-2"
                                  : "bg-success text-white p-2"
                              }
                            >
                              <p className="m-0 p-0">MTN Marketplace</p>
                              <p className="m-0 p-0">
                                <b>
                                  ₦
                                  {formatCurrency(
                                    tempData.data.marketplace_mtn_data
                                  )}
                                </b>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col lg="3" xs="6">
                          <Card className="mini-stat  shadow">
                            <CardBody
                              className={
                                tempData.data.withdrawals * 1 > 1
                                  ? "bg-danger text-white p-2"
                                  : "bg-success text-white p-2"
                              }
                            >
                              <p className="m-0 p-0">Withdrawals</p>
                              <p className="m-0 p-0">
                                <b> {tempData.data.withdrawals}</b>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <div className="mb-2">
                    <p className="text-uppercase text-left mb-0 text-primary ">
                      <b>Servers</b>
                    </p>
                    <hr className=" m-1 p-0" />
                  </div>
                  <Row>
                    <Col lg="3" xs="12">
                      <Card className="mini-stat  shadow">
                        <CardBody>
                          <img src={mtn} style={{ width: 32 }} alt="MTN" />
                          <div>
                            {tempData.data.MTNDataServers.map((server) => {
                              return (
                                // do not display server with updated params more than a month
                                (new Date() - new Date(server.updated_at)) /
                                  60000 <
                                  43200 &&
                                !server.name.includes("S") && (
                                  <label
                                    className={
                                      (new Date() -
                                        new Date(server.updated_at)) /
                                        60000 <
                                      3
                                        ? "p-1 mr-1 text-white bg-success small"
                                        : "p-1 mr-1 text-white bg-danger small"
                                    }
                                  >
                                    {server.name} [₦
                                    {formatCurrency(server.airtime_balance)} ,
                                    {formatCurrency(server.data_balance / 1024)}
                                    GB ]
                                  </label>
                                )
                              );
                            })}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg="3" xs="12">
                      <Card className="mini-stat  shadow">
                        <CardBody>
                          <img src={glo} style={{ width: 32 }} alt="GLO" />
                          <div>
                            {tempData.data.GLODataServers.map((server) => {
                              return (
                                // do not display server with updated params more than a month
                                (new Date() - new Date(server.updated_at)) /
                                  60000 <
                                  43200 && (
                                  <label
                                    className={
                                      (new Date() -
                                        new Date(server.updated_at)) /
                                        60000 <
                                      3
                                        ? "p-1 mr-1 text-white bg-success small"
                                        : "p-1 mr-1 text-white bg-danger small"
                                    }
                                  >
                                    {server.name} [₦
                                    {formatCurrency(server.airtime_balance)} ]
                                  </label>
                                )
                              );
                            })}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg="3" xs="12">
                      <Card className="mini-stat  shadow">
                        <CardBody>
                          <img
                            src={airtel}
                            style={{ width: 32 }}
                            alt="AIRTEL"
                          />
                          <div>
                            {tempData.data.AIRTELDataServers.map((server) => {
                              return (
                                // do not display server with updated params more than a month
                                (new Date() - new Date(server.updated_at)) /
                                  60000 <
                                  43200 && (
                                  <label
                                    className={
                                      (new Date() -
                                        new Date(server.updated_at)) /
                                        60000 <
                                      3
                                        ? "p-1 mr-1 text-white bg-success small"
                                        : "p-1 mr-1 text-white bg-danger small"
                                    }
                                  >
                                    {server.name} [₦
                                    {formatCurrency(server.airtime_balance)} ]
                                  </label>
                                )
                              );
                            })}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="3" xs="12">
                      <Card className="mini-stat  shadow">
                        <CardBody>
                          <img
                            src={etisalat}
                            style={{ width: 32 }}
                            alt="ETISALAT"
                          />
                          <div>
                            {tempData.data.ETISALATDataServers.map((server) => {
                              return (
                                // do not display server with updated params more than a month
                                (new Date() - new Date(server.updated_at)) /
                                  60000 <
                                  43200 && (
                                  <label
                                    className={
                                      (new Date() -
                                        new Date(server.updated_at)) /
                                        60000 <
                                      3
                                        ? "p-1 mr-1 text-white bg-success small"
                                        : "p-1 mr-1 text-white bg-danger small"
                                    }
                                  >
                                    {server.name} [₦
                                    {formatCurrency(server.airtime_balance)} ]
                                  </label>
                                )
                              );
                            })}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <div className="mb-2">
                    <p className="text-uppercase text-left mb-0 text-primary">
                      <b>Data Controllers</b>
                    </p>
                    <hr className=" m-1 p-0" />
                  </div>

                  <Row>
                    <Col lg="3" xs="12">
                      <Card className="mini-stat  shadow">
                        <CardBody>
                          <img src={mtn} style={{ width: 32 }} alt="MTN" />
                          <p className="m-1 small">
                            <b>
                              Normal :{" "}
                              {tempData.data.adminSettings.MTN_data_normal *
                                1 ===
                              0
                                ? "Enabled"
                                : "Disabled"}
                              {tempData.data.adminSettings.MTN_data_normal *
                                1 ===
                              0 ? (
                                <button
                                  className="bg-danger btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("MTN_data_normal", 1)
                                  }
                                >
                                  Disable
                                </button>
                              ) : (
                                <button
                                  className="bg-success btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("MTN_data_normal", 0)
                                  }
                                >
                                  Enable
                                </button>
                              )}
                            </b>
                          </p>

                          <p className=" m-1 small">
                            <b>
                              SME :{" "}
                              {tempData.data.adminSettings.MTN_data_sme * 1 ===
                              0
                                ? "Enabled"
                                : "Disabled"}
                              {tempData.data.adminSettings.MTN_data_sme * 1 ===
                              0 ? (
                                <button
                                  className="bg-danger btn ml-2 text-white  btn-sm"
                                  onClick={() => fetchStats("MTN_data_sme", 1)}
                                >
                                  Disable
                                </button>
                              ) : (
                                <button
                                  className="bg-success btn ml-2 text-white  btn-sm"
                                  onClick={() => fetchStats("MTN_data_sme", 0)}
                                >
                                  Enable
                                </button>
                              )}
                            </b>
                          </p>

                          <p className="m-1 small">
                            <b>
                              Corporate :{" "}
                              {tempData.data.adminSettings.MTN_data_corporate *
                                1 ===
                              0
                                ? "Enabled"
                                : "Disabled"}
                              {tempData.data.adminSettings.MTN_data_corporate *
                                1 ===
                              0 ? (
                                <button
                                  className="bg-danger btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("MTN_data_corporate", 1)
                                  }
                                >
                                  Disable
                                </button>
                              ) : (
                                <button
                                  className="bg-success btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("MTN_data_corporate", 0)
                                  }
                                >
                                  Enable
                                </button>
                              )}
                            </b>
                          </p>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg="3" xs="12">
                      <Card className="mini-stat  shadow">
                        <CardBody>
                          <img src={glo} style={{ width: 32 }} alt="GLO" />
                          <p className="m-1 small">
                            <b>
                              Normal :{" "}
                              {tempData.data.adminSettings.GLO_data_normal *
                                1 ===
                              0
                                ? "Enabled"
                                : "Disabled"}
                              {tempData.data.adminSettings.GLO_data_normal *
                                1 ===
                              0 ? (
                                <button
                                  className="bg-danger btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("GLO_data_normal", 1)
                                  }
                                >
                                  Disable
                                </button>
                              ) : (
                                <button
                                  className="bg-success btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("GLO_data_normal", 0)
                                  }
                                >
                                  Enable
                                </button>
                              )}
                            </b>
                          </p>

                          <p className=" m-1 small">
                            <b>
                              SME :{" "}
                              {tempData.data.adminSettings.GLO_data_sme * 1 ===
                              0
                                ? "Enabled"
                                : "Disabled"}
                              {tempData.data.adminSettings.GLO_data_sme * 1 ===
                              0 ? (
                                <button
                                  className="bg-danger btn ml-2 text-white  btn-sm"
                                  onClick={() => fetchStats("GLO_data_sme", 1)}
                                >
                                  Disable
                                </button>
                              ) : (
                                <button
                                  className="bg-success btn ml-2 text-white  btn-sm"
                                  onClick={() => fetchStats("GLO_data_sme", 0)}
                                >
                                  Enable
                                </button>
                              )}
                            </b>
                          </p>

                          <p className="m-1 small">
                            <b>
                              Corporate :{" "}
                              {tempData.data.adminSettings.GLO_data_corporate *
                                1 ===
                              0
                                ? "Enabled"
                                : "Disabled"}
                              {tempData.data.adminSettings.GLO_data_corporate *
                                1 ===
                              0 ? (
                                <button
                                  className="bg-danger btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("GLO_data_corporate", 1)
                                  }
                                >
                                  Disable
                                </button>
                              ) : (
                                <button
                                  className="bg-success btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("GLO_data_corporate", 0)
                                  }
                                >
                                  Enable
                                </button>
                              )}
                            </b>
                          </p>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg="3" xs="12">
                      <Card className="mini-stat  shadow">
                        <CardBody>
                          <img
                            src={airtel}
                            style={{ width: 32 }}
                            alt="AIRTEL"
                          />
                          <p className="m-1 small">
                            <b>
                              Normal :{" "}
                              {tempData.data.adminSettings.AIRTEL_data_normal *
                                1 ===
                              0
                                ? "Enabled"
                                : "Disabled"}
                              {tempData.data.adminSettings.AIRTEL_data_normal *
                                1 ===
                              0 ? (
                                <button
                                  className="bg-danger btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("AIRTEL_data_normal", 1)
                                  }
                                >
                                  Disable
                                </button>
                              ) : (
                                <button
                                  className="bg-success btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("AIRTEL_data_normal", 0)
                                  }
                                >
                                  Enable
                                </button>
                              )}
                            </b>
                          </p>

                          <p className=" m-1 small">
                            <b>
                              SME :{" "}
                              {tempData.data.adminSettings.AIRTEL_data_sme *
                                1 ===
                              0
                                ? "Enabled"
                                : "Disabled"}
                              {tempData.data.adminSettings.AIRTEL_data_sme *
                                1 ===
                              0 ? (
                                <button
                                  className="bg-danger btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("AIRTEL_data_sme", 1)
                                  }
                                >
                                  Disable
                                </button>
                              ) : (
                                <button
                                  className="bg-success btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("AIRTEL_data_sme", 0)
                                  }
                                >
                                  Enable
                                </button>
                              )}
                            </b>
                          </p>

                          <p className="m-1 small">
                            <b>
                              Corporate :{" "}
                              {tempData.data.adminSettings
                                .AIRTEL_data_corporate *
                                1 ===
                              0
                                ? "Enabled"
                                : "Disabled"}
                              {tempData.data.adminSettings
                                .AIRTEL_data_corporate *
                                1 ===
                              0 ? (
                                <button
                                  className="bg-danger btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("AIRTEL_data_corporate", 1)
                                  }
                                >
                                  Disable
                                </button>
                              ) : (
                                <button
                                  className="bg-success btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("AIRTEL_data_corporate", 0)
                                  }
                                >
                                  Enable
                                </button>
                              )}
                            </b>
                          </p>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg="3" xs="12">
                      <Card className="mini-stat  shadow">
                        <CardBody>
                          <img
                            src={etisalat}
                            style={{ width: 32 }}
                            alt="ETISALAT"
                          />
                          <p className="m-1 small">
                            <b>
                              Normal :{" "}
                              {tempData.data.adminSettings
                                .ETISALAT_data_normal *
                                1 ===
                              0
                                ? "Enabled"
                                : "Disabled"}
                              {tempData.data.adminSettings
                                .ETISALAT_data_normal *
                                1 ===
                              0 ? (
                                <button
                                  className="bg-danger btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("ETISALAT_data_normal", 1)
                                  }
                                >
                                  Disable
                                </button>
                              ) : (
                                <button
                                  className="bg-success btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("ETISALAT_data_normal", 0)
                                  }
                                >
                                  Enable
                                </button>
                              )}
                            </b>
                          </p>

                          <p className=" m-1 small">
                            <b>
                              SME :{" "}
                              {tempData.data.adminSettings.ETISALAT_data_sme *
                                1 ===
                              0
                                ? "Enabled"
                                : "Disabled"}
                              {tempData.data.adminSettings.ETISALAT_data_sme *
                                1 ===
                              0 ? (
                                <button
                                  className="bg-danger btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("ETISALAT_data_sme", 1)
                                  }
                                >
                                  Disable
                                </button>
                              ) : (
                                <button
                                  className="bg-success btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("ETISALAT_data_sme", 0)
                                  }
                                >
                                  Enable
                                </button>
                              )}
                            </b>
                          </p>

                          <p className="m-1 small">
                            <b>
                              Corporate :{" "}
                              {tempData.data.adminSettings
                                .ETISALAT_data_corporate *
                                1 ===
                              0
                                ? "Enabled"
                                : "Disabled"}
                              {tempData.data.adminSettings
                                .ETISALAT_data_corporate *
                                1 ===
                              0 ? (
                                <button
                                  className="bg-danger btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("ETISALAT_data_corporate", 1)
                                  }
                                >
                                  Disable
                                </button>
                              ) : (
                                <button
                                  className="bg-success btn ml-2 text-white  btn-sm"
                                  onClick={() =>
                                    fetchStats("ETISALAT_data_corporate", 0)
                                  }
                                >
                                  Enable
                                </button>
                              )}
                            </b>
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(Payments);
